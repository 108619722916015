@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap');

@import 'components/_scan.scss';
@import 'components/_cursor.scss';
@import 'components/_menu.scss';

* {
  box-sizing: border-box;
  user-select: none;
}

$background: white;

// GENERAL

#leva__root {
  z-index: 999999999999999999999999;
  position: relative;
  display: none !important;
}

html,
body,
#root,
.screen {
  background: $background;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

$border-size: 20px;
$corner-size: 40px;

body {
  color: white;
  border: 20px solid white;
  // clip-path: polygon(
  //   $border-size $border-size,
  //   calc(100% - $border-size) $border-size,
  //   calc(100% - $border-size) calc(100% - $corner-size),
  //   calc(100% - $corner-size) calc(100% - $border-size),
  //   $border-size calc(100% - $border-size)
  // );
  opacity: 0;
  animation: fade-in 3s ease 1s forwards;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 150;
    mix-blend-mode: overlay;
    pointer-events: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  width: 100%;
  height: 100%;
  width: 700px;
  margin: 0 auto;
  touch-action: none;
  background: $background;
}

// TYPEFACES

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Old Standard TT', sans-serif;
  color: white;
}

p {
  opacity: 0.6;
  margin: 0;
  touch-action: none;
  user-select: none;
  display: inline-block;
  margin-top: 20px;
  font-family: sans-serif;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

a {
  display: inline-block;
  margin-top: 30px;
  border: 0;
  background: none;
  padding: 0;
}

h1 {
  margin: 0;
  font-size: 70px;
  line-height: 80px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 160px;
}
h2 {
  margin: 0;
  font-size: 2rem;
}
h4 {
  margin: 0;
  margin-top: 1.2rem;
  font-size: 18px;
  letter-spacing: 0.05rem;
  text-transform: lowercase;
  font-family: sans-serif;
  font-weight: 400;
}

button {
  display: inline-block;
  padding: 15px 30px;
  background: white;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: black;
  border: none;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  z-index: 9999999999;
  &:hover {
    outline: 0;
  }
}

// COMPONENTS

.logo {
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 99999;
  width: 200px;
}

.ui-center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ui-center__container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

// Cursor

// .c-cursor {
//   border: 2px solid white;
//   background: none !important;
// }

.c-cursor__inner {
  font-size: 2rem !important;
}

// #stick-button {
//   text-align: center;
//   margin: 0 auto;
//   display: inline-block;
// }

@media (max-width: 767px) {
  .c-cursor {
    display: none !important;
  }
  .logo {
    top: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  h1 {
    width: 80%;
    font-size: 60px;
    line-height: 60px;
  }
  .menu {
    display: none;
  }
}

@media (max-width: 480px) {
  h1 {
    width: 80%;
    font-size: 40px;
    line-height: 40px;
  }
}
